"use client";

import React from "react";
import SignInForm from "./SignInForm";
import Navbar from "../components/Navbar";

const SignInPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <SignInForm />
    </div>
  );
};

export default SignInPage;
